// angular module
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';



// primeNG module
import { ToastModule } from 'primeng/toast';
import { DropdownModule } from 'primeng/dropdown';
import { RadioButtonModule } from 'primeng/radiobutton'
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { CheckboxModule } from 'primeng/checkbox';
import { InputTextModule } from 'primeng/inputtext';
import { PasswordModule } from 'primeng/password';
import { DividerModule } from 'primeng/divider';
import { InputMaskModule } from 'primeng/inputmask';


// angular compoment
import { AppComponent } from './app.component';
import { RegisterComponent } from './views/register/register.component';
import { LoginComponent } from './views/login/login.component';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { DialogModule } from 'primeng/dialog';
import { FilterPipe } from './pipes/filter.pipe';
import { AdminComponent } from './views/dashboard/admin/admin.component';
import { ConfirmationService, MessageService } from 'primeng/api';
import { SendResetPasswordEmailComponent } from './views/send-reset-password-email/send-reset-password-email.component';
import { ResetPasswordComponent } from './views/reset-password/reset-password.component';
import { ResetPasswordMessageComponent } from './views/reset-password-message/reset-password-message.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { PanelMenuModule } from 'primeng/panelmenu';
import { TokenInterceptorProvider } from './utils/auth/auth.interceptor';
import { SharedModule } from './shared/shared.module';
import { VerifyOtpComponent } from './views/verify-otp/verify-otp.component';
import { UpdatePasswordComponent } from './views/update-password/update-password.component';







@NgModule({
  declarations: [
    AppComponent,
    FilterPipe,
    RegisterComponent,
    LoginComponent,
    DashboardComponent,
    AdminComponent,
    SendResetPasswordEmailComponent,
    ResetPasswordComponent,
    ResetPasswordMessageComponent,
    VerifyOtpComponent,
    UpdatePasswordComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    ToastModule,
    DropdownModule,
    RadioButtonModule,
    ConfirmDialogModule,
    DialogModule,
    CheckboxModule,
    InputTextModule,
    PasswordModule,
    NgxPaginationModule,
    DividerModule,
    PanelMenuModule,
    InputMaskModule,
    SharedModule
  ],
  providers: [
    MessageService,
    ConfirmationService,
    TokenInterceptorProvider,
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
